import '../sass/app.scss';
import Player from './Player.js';
import Viewer from './Viewer.js'

function App() {
  const defaultSlideSpeed = 3000;
  const defaultVariation = 1000;

  const slideSpeedRandomizer = (speed, variation) => {
    return (speed - (variation / 2)) + (Math.random() * variation);
  }

  return (
    <div className="site-container">
      <header className='main-header section-container'>
        <nav className='main-navigation'>
          <ul>
            <li>Digital product design</li>
            <li><a href='#work'>Work</a></li>
            <li><a href='#about'>About</a></li>
          </ul>
        </nav>
        <div className='grid-container'>
          <div className='grid-item col-3'></div>
          <div className='grid-item col-6 introduction'>
            <h1 className='main-title'><span>Hello!&nbsp;</span><span>I'm René Ooms,</span></h1>
            <p className='main-paragraph'>As digital product designer I turn your ideas into impactful experiences. With a keen ability to quickly tune into your industry, I design without bounds, ensuring peak efficiency and innovation.</p>
            <a className='cta-button' href='mailto:mail@reneooms.com'>
              <span>Let's collaborate</span>
              <span>and bring your vision to life</span>
            </a>
          </div>
          <div className='profile-photo'></div>
        </div>
      </header>
      <section id="work" className='portfolio section-container'>
        <div className='grid-container portfolio-row'>
          <div className='grid-item col-4 spacing-2'>
            <Player
              settings={{
                placeholder: 'path/to/placeholder.jpg',
                directReveal: true
              }}
              video={[
                { url: 'img/MM-logo.mp4', type: 'video/mp4' }
              ]}
            />
          </div>
          <div className='grid-item col-6'>
            <Viewer
              settings={{
                baseHeight: 785,
                baseWidth: 785,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation),
                directReveal: true
              }}
              items={[
                { url: 'img/MM-Mockup-1.jpg', altText: 'MedievalMe visual 1' },
                { url: 'img/MM-Mockup-3.jpg', altText: 'MedievalMe visual 2' }
              ]}
            />
          </div>
        </div>
        <div className='grid-container portfolio-row spacing-3'>
          <div className='grid-item col-6 spacing-3'>
            <Viewer
              settings={{
                baseHeight: 785,
                baseWidth: 785,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/axxs-mockup-1.jpg', altText: 'AXXS visual 1' },
                { url: 'img/axxs-mockup-2.jpg', altText: 'AXXS visual 2' }
              ]}
            />
          </div>
          <div className='grid-item col-4'>
            <Viewer
              settings={{
                baseHeight: 482,
                baseWidth: 495,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/AXXS-impression-1.jpg', altText: 'AXXS Logo impression' }
              ]}
            />
          </div>
        </div>
        <div className='grid-container portfolio-row spacing-2 ignore-mobile'>
          <div className='grid-item col-3 col-offset-1 spacing-4'>
            <Viewer
              settings={{
                baseHeight: 432,
                baseWidth: 363,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/logo-snap-track.jpg', altText: 'Logo Snap \'n Track' }
              ]}
            />
          </div>
          <div className='grid-item col-3 spacing-2'>
            <Player
              settings={{
                placeholder: 'img/cilio-logo.png'
              }}
              video={[
                { url: 'img/Cilio-logo-animation2.mp4', type: 'video/mp4' }
              ]}
            />
          </div>
          <div className='grid-item col-3'>
            <Player
              settings={{
                placeholder: 'img/placeholder.jpg'
              }}
              video={[
                { url: 'img/AXXS-logo-animation.mp4', type: 'video/mp4' }
              ]}
            />
          </div>

        </div>
        <div className='grid-container portfolio-row spacing-2'>
          <div className='grid-item col-5'>
            <Viewer
              settings={{
                baseHeight: 774,
                baseWidth: 626,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/Lokka-impression-1.jpg', altText: 'Lokka posters impression' },
                { url: 'img/Lokka-impression-2.jpg', altText: 'Lokka socials impression' }
              ]}
            />
          </div>
          <div className='grid-item col-5 spacing-6'>
            <Viewer
              settings={{
                baseHeight: 648,
                baseWidth: 626,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/Lokka-mockup-1.jpg', altText: 'Lokka consumer app impression' },
                { url: 'img/Lokka-mockup-2.jpg', altText: 'Lokka consumer app impression' },
                { url: 'img/Lokka-mockup-3.jpg', altText: 'Lokka consumer app impression' }
              ]}
            />
          </div>
        </div>
        <div className='grid-container portfolio-row spacing-3 ignore-mobile'>
          <div className='grid-item col-9 col-offset-1'>
            <Viewer
              settings={{
                baseHeight: 666,
                baseWidth: 1154,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/most-dev-impression-1.jpg', altText: 'Most.dev logo impression' },
                { url: 'img/most-dev-impression-2.jpg', altText: 'Most.dev logo impression' }
              ]}
            />
          </div>
        </div>
        <div className='grid-container portfolio-row spacing-2'>
          
          <div className='grid-item col-6'>
            <Viewer
              settings={{
                baseHeight: 655,
                baseWidth: 626,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/Snap-N-Track-mockup-1.jpg', altText: 'Snap N Track Golf app mockups' },
                { url: 'img/Snap-N-Track-mockup-2.jpg', altText: 'Snap N Track Golf app mockups' },
                { url: 'img/Snap-N-Track-mockup-3.jpg', altText: 'Snap N Track Golf app mockups' }
              ]}
            />
          </div>
          <div className='grid-item col-4 spacing-6'>
            <Player
              settings={{
                placeholder: 'img/mynze-impression-1.jpg'
              }}
              video={[
                { url: 'img/mynze-logo-animation.mp4', type: 'video/mp4' }
              ]}
            />
          </div>
        </div>
        <div className='grid-container portfolio-row spacing-2'>
          <div className='grid-item col-4'>
            <Viewer
              settings={{
                baseHeight: 486,
                baseWidth: 495,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/DIAS-mockup.jpg', altText: 'DIAS consumer app mockup' }
              ]}
            />
          </div>
          <div className='grid-item col-6 spacing-3'>
            <Viewer
              settings={{
                baseHeight: 758,
                baseWidth: 758,
                slideSpeed: slideSpeedRandomizer(defaultSlideSpeed, defaultVariation)
              }}
              items={[
                { url: 'img/Innocubes-impression-1.jpg', altText: 'DIAS consumer app mockup' },
                { url: 'img/Innocubes-impression-2.jpg', altText: 'DIAS consumer app mockup' }
              ]}
            />
          </div>
        </div>
      </section>
      <section id="about" className='services section-container'>
        <div className='grid-container'>
          <div className='grid-item col-5'>
            <div className='content-wrap'>
              <h2>My Expertise</h2>
            </div>
          </div>
          <div className='grid-item col-5'>
            <ul className='service-list'>
              <li>Product discovery & design</li>
              <li>Product positioning</li>
              <li>Prototyping</li>
              <li>Creative front-end development</li>
            </ul>
          </div>
        </div>
      </section>
      <section className='about'>
        <div className='grid-container'>
          <div className='grid-item col-5'>
            <div className='content-wrap'>
              <h2>
                Always evolving,<br />
                endless creation
              </h2>
              <p>Somewhere around the age of 7 my grandfather casually placed an Amiga 500 in my parents' living room just to see what would happen. That simple act sparked a lifelong journey; learning, puzzling, designing, building, and experimenting. Unrestricted by a specific process, I quickly discern what's effective and pivot as needed.</p>
              <p>That’s what I still do today. At leading agency’s, corporate teams, or lone visionaries dreaming of the next digital breakthrough.</p>
              <p>From crafting a game for a children's hospital to designing a digital assistant for a corporate giant, my range isn't a lack of focus, it's a testament to adaptability. My strength lies in rapidly grasping the heart of diverse industries, offering fresh perspectives unbound by inside conventions.</p>
            </div>
          </div>
          <div className='grid-item col-5 doyle-insert'>
            <img src='/img/logo-doyle.svg' alt='Doyle logo' />
            <h2 className='ghost'>About Doyle Studio</h2>
            <p className='extra-attention'>Doyle, my agency together with Romeo Vreeken, unites elite creatives in a collaborative spirit. At Doyle, we transform ideas into fully-realized MVPs.</p>
            <a className='cta-button' href='http://www.doyle.nl' target='_blank' rel='noreferrer'>
              <span>Open the Doyle website</span>
            </a>
          </div>
        </div>
      </section>
      <footer className='main-footer'>Copyright 2023 René Ooms Design & Technology</footer>
    </div>
  );
}

export default App;