import React, { useEffect, useState, useCallback, useRef } from "react";

function Viewer({ settings, items}) {

  const viewerContainerRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [loadCounter, setLoadCounter] = useState(0);
  const [componentReady, setComponentReady] = useState(false);
  const [componentRevealed, setComponentRevealed] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const nextPosition = useCallback(() => {
    setCurrentPosition(prev => (prev < loadCounter - 1) ? prev + 1 : 0);
  }, [loadCounter]);

  useEffect(() => {
    let interval;
    if (loadCounter > 1 && isActive) {
      interval = setInterval(nextPosition, settings.slideSpeed);
      return () => clearInterval(interval);
    }
  }, [nextPosition, settings.slideSpeed, isActive, loadCounter, componentRevealed, setComponentRevealed]);

  useEffect(() => {
    let timeOut;
    if (loadCounter > 0 && !componentReady) {
      timeOut = setTimeout(() => { setComponentReady(true); }, 500)
    }
    return () => clearTimeout(timeOut);
  }, [loadCounter, componentReady]);

  useEffect(() => {
    if(settings.directReveal) {
      setComponentRevealed(true);
    }
  }, [settings.directReveal])

  useEffect(() => {
    const handleIntersect = ([entry]) => {
      const newActiveState = entry.isIntersecting;
      if (isActive !== newActiveState) {
        setIsActive(newActiveState);
        if (!componentRevealed) {
          setComponentRevealed(true);
        }
      }
      if (newActiveState) {
        setCurrentPosition(0);
      }
    };

    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      threshold: 0.3,
    });

    if (viewerContainerRef.current) {
      observer.observe(viewerContainerRef.current);
    }

    return () => observer.disconnect();
  }, [isActive, setCurrentPosition, componentRevealed]);

  const calculateZIndex = (index) => {
    let newZIndex;
    if (index === currentPosition) {
      newZIndex = loadCounter;
    } else if (index < currentPosition) {
      newZIndex = 1;
    } else if (index > currentPosition) {
      newZIndex = 0;
    }
    return newZIndex;
  }

  const imageLoadHandler = (e) => {
    setLoadCounter(loadCounter + 1);
  }

  return (
    <div
      ref={viewerContainerRef}
      className={`viewer-component ${componentRevealed ? 'reveal' : ''}`}
      style={{
        paddingTop: `${settings.baseHeight / settings.baseWidth * 100}%`
      }}
    >
      {!componentReady && (<div className={`viewer-loader ${loadCounter > 0 ? 'loaded' : ''}`}></div>)}
      {/*componentReady && loadCounter > 1 && (
        <svg className="viewer-controll" width="31" height="31">
          <circle className="controll-progress" cx="15.5" cy="15.5" r="10.5"/>
          <circle className="controll-background" cx="15.5" cy="15.5" r="10.5"/>
        </svg>
      )*/}
      {items.map((item, index) => (
        index < loadCounter + 1 ? (
          <div
            className={`viewer-item ${currentPosition === index ? 'selected' : ''}`}
            key={item.id || index}
            style={{ zIndex: calculateZIndex(index) }}
          >
            <img
              className="viewer-image"
              src={item.url} alt={item.altText}
              onLoad={imageLoadHandler}
            />
          </div>
        ) : (
          <div key={item.id || index}></div>
        )
      ))}
    </div>
  );
}

export default Viewer;