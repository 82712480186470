import React, { useEffect, useState, useRef } from "react";

function Player({settings, video }) {

  const viewerContainerRef = useRef(null);
  const [componentRevealed, setComponentRevealed] = useState(false);
  const [isActive, setIsActive] = useState(false);
  
  useEffect(() => {
    if(settings.directReveal) {
      setComponentRevealed(true);
    }
  }, [settings.directReveal])
  
  useEffect(() => {
    const handleIntersect = ([entry]) => {
      const newActiveState = entry.isIntersecting;
      if (isActive !== newActiveState) {
        setIsActive(newActiveState);
        if (!componentRevealed) {
          setComponentRevealed(true);
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      threshold: 0.3,
    });

    if (viewerContainerRef.current) {
      observer.observe(viewerContainerRef.current);
    }

    return () => observer.disconnect();
  }, [isActive, componentRevealed]);

  return (
    <div 
      className={`player-component ${componentRevealed ? 'reveal' : ''}`}
      ref={viewerContainerRef}
    >
      <video 
        className="player-video" 
        poster={settings.placeholder}
        muted 
        autoPlay 
        loop 
        playsInline>
        {video.map((params, index) => (
          <source key={params.id || index} src={params.url} type={params.type} />
        ))}
      </video>
    </div>
  );
}

export default Player;